var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-modal", {
    ref: "modal-create-team",
    attrs: {
      id: "modal-create-team",
      "footer-class": "flex-nowrap",
      "no-close-on-backdrop": "",
      centered: ""
    },
    scopedSlots: _vm._u([
      {
        key: "modal-header",
        fn: function() {
          return [_c("h3", [_vm._v(_vm._s(_vm.$t("modals.createTeam.title")))])]
        },
        proxy: true
      },
      {
        key: "default",
        fn: function() {
          return [
            _c("modal-choose-team-name-and-description", {
              attrs: {
                teamName: _vm.teamName,
                teamDescription: _vm.teamDescription
              },
              on: {
                "update-fields": _vm.updateFields,
                "enter-pressed": _vm.createTeam
              }
            })
          ]
        },
        proxy: true
      },
      {
        key: "modal-footer",
        fn: function() {
          return [
            _c(
              "b-button",
              {
                staticClass: "p-3 border-right-light-grey",
                attrs: { block: "" },
                on: {
                  click: function($event) {
                    return _vm.$emit("cancel")
                  }
                }
              },
              [_vm._v(" " + _vm._s(_vm.$t("buttons.cancel")) + " ")]
            ),
            _c(
              "b-button",
              {
                staticClass: "p-3",
                attrs: {
                  disabled: !_vm.isTeamNameSet,
                  variant: "primary",
                  block: ""
                },
                on: { click: _vm.createTeam }
              },
              [_vm._v(" " + _vm._s(_vm.$t("modals.createTeam.validate")) + " ")]
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }